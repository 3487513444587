import React from "react";
import Layout from "../../../components/Layout";
import PageHeader from "../../../components/PageHeader";
import ContentResources from "../../../components/ContentResources";
import Conditions from "../../../components/Conditions";
import Information from "../../../components/Information";
import Map from "../../../components/Map";

const Resources = () => {
  return (
    <Layout>
      <PageHeader text="Patient Resources" />
      <ContentResources />
      <Conditions />
      <Information />
      <Map />
    </Layout>
  );
};

export default Resources;
