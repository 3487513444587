import React from "react";
import { Link } from "gatsby";

const ResourceLink = ({ children, path }) => {
  return (
    <Link to={path}>
      <p className="text-lg text-sunstate-blue mb-3">{children}</p>
    </Link>
  );
};

export default ResourceLink;
