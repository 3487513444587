import React from "react";
import pothen2 from "../img/pothen2.jpeg";
import ResourceLink from "./ResourceLink";
import OutsideResource from "./OutsideResource";

const ContentResources = () => {
  return (
    <div className="w-full px-8 flex flex-col py-16 justify-center items-center">
      <div className="w-full lg:w-3/5 flex flex-col lg:flex-row justify-center items-center lg:items-start lg:justify-start mb-12">
        <img alt="staff" className="w-64 max-w-full" src={pothen2} />
        <div className="flex flex-col justify-start items-center lg:items-start px-4">
          <p className="text-2xl text-center text-gray-700 font-bold mb-4">
            Patient Resources
          </p>
          <p className="text-lg text-center lg:text-left text-gray-600">
            Both health professionals and patients depend on the following
            resources for information on diseases, conditions and various
            wellness issues. We encourage you to use these sites to learn about
            the latest treatments, look up information on a drug or supplement,
            find out the meanings of words, or view medical videos or
            illustrations. You can also get links to the latest medical research
            on your topic or find out about clinical trials on a disease or
            condition.
          </p>
        </div>
      </div>
      <div className="w-full lg:w-3/5 flex flex-col justify-center items-start mb-6">
        <p className="text-2xl text-center text-gray-700 font-bold mb-4">
          General Health and Wellness Resources
        </p>
        <ResourceLink path="/contents/resources/abi-ankle-brachial-index-test">
          ABI (Ankle-Brachial Index)
        </ResourceLink>
        <ResourceLink path="/contents/resources/vaccination-for-adults">
          Vaccination for Adults
        </ResourceLink>
        <ResourceLink path="/contents/resources/high-blood-pressure">
          High Blood Pressure
        </ResourceLink>
        <ResourceLink path="/contents/resources/high-cholesterol">
          High Cholesterol
        </ResourceLink>
        <ResourceLink path="/contents/resources/mammograms">
          Mammograms
        </ResourceLink>
        <ResourceLink path="/contents/resources/tetanus-vaccination">
          Tetanus Vaccination
        </ResourceLink>
        <ResourceLink path="/contents/resources/why-do-i-need-a-colonoscopy">
          Why Do I Need A Colonoscopy?
        </ResourceLink>
        <ResourceLink path="/contents/resources/hemorrhoids">
          Hemorrhoids
        </ResourceLink>
      </div>
      <div className="w-full lg:w-3/5 flex flex-col justify-center items-start">
        <p className="text-2xl text-center text-gray-700 font-bold mb-4">
          Links to outside resources
        </p>
        <OutsideResource path="http://www.mayoclinic.com/health-information/">
          Mayo Clinic
        </OutsideResource>
        <OutsideResource path="https://uspreventiveservicestaskforce.org/uspstf/">
          U.S. Preventive Services Task Force
        </OutsideResource>
        <OutsideResource path="http://www.nlm.nih.gov/medlineplus/">
          MedlinePlus – Health Information from the National Library of Medicine
        </OutsideResource>
        <OutsideResource path="http://wwwnc.cdc.gov/travel/">
          Travelers’ Health
        </OutsideResource>
      </div>
    </div>
  );
};

export default ContentResources;
